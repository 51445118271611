@font-face {
  font-family: OpenSans;
  src: url(assets/fonts/font-openSans/OpenSans-Regular.ttf) format();
}
body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

a:hover {
  color: white;
}

.faeture-heading {
  text-align: center;
  padding-bottom: 18px;
}

.saving-parent {
  text-align: center;
  padding-top: 70px;
}

section.features {
  background-color: black;
  color: white;
}

.features-parent {
  padding: 100px 0;
}

.features-parent22 {
  padding: 60px 0;
}

.faeture-heading h5 {
  color: #066D72;
}

.faeture-heading h1 {
  font-size: 48px;
  font-weight: bold;
  margin: 17px 0;
}

.feature-icon {
  margin-bottom: 40px;
}

.monthly-parent {
  padding: 100px 0;
  margin: 80px 0;
}

.feature-icon {
  height: 65px;
  width: 65px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background-color: #066D72;
  border-radius: 3px;
  margin-bottom: 30px;
}

.saving-parent h5 {
  font-size: 25px;
  font-weight: bold;
}

p.content {
  padding-top: 16px;
  line-height: 26px;
}

div#accordionFlushExample {
  margin-top: 40px;
}

h1.headeingtext {
  font-weight: 700;
}

h1.headeingtext {
  font-size: 45px;
  margin-top: 20px;
}

.saving-parent1 {
  text-align: center;
}

.saving-parent1 {
  text-align: center;
}

.textsaving {
  font-size: 25px;
  font-weight: bold;
}

button {
  border: none;
  background-color: black;
  padding: 13px 21px;
  border-radius: 31px;
  color: white;
  font-weight: 400;
  font-size: 20px;
}

.download-btn {
  display: flex;
  justify-content: end;
}

section.case {
  padding-top: 80px;
}

.images-parent {
  padding-top: 60px;
  text-align: center;
}

.dowload-parent h1 {
  font-weight: bold;
}

.dowload-parent h5 {
  color: #066D72;
}

.row.parent {
  display: flex;
  align-items: center;
}

section.get {
  background-color: #0e6e72;
}

.get-parent {
  margin-top: 80px;
}

.faeture-heading1 {
  text-align: center;
  color: white;
}

button a {
  text-decoration: none;
  color: white;
}

button:hover,
button:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  background-color: #066D72;
  transition: background-color 0.1s ease;
  transition-duration: 2.5s;
}

.get-parent h5 {
  color: white;
}

.banner-ctn h5 {
  color: #066d72;
}

.banner-ctn h1 {
  font-weight: bold;
}

a.watch-btn {
  color: black;
  margin-left: 13px;
}

button.form1 {
  padding: 10px;
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group {
  margin: 20px 0;
}

.fakeimg1 {
  text-align: end;
  position: relative;
}

.fakeimg1aim {
  text-align: start;
  position: relative;
}

section.mybanner {
  padding-top: 80px;
}

.fake-parent.col-sm-12.col-md-6.col-lg-6 {
  display: grid;
  align-items: center;
}

button.form1 {
  padding: 10px;
  width: 100%;
  border-radius: 0;
}

.bottom-center {
  position: absolute;
  left: 50%;
  text-align: left;
  transform: translate(-50%, -50%);
}

.card-header.savings {
  border: none;
}

.card.savings {
  background-color: white;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.saving-parent11 {
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #e1e1e1;
}

.saving-img {
  border: 1px solid #ede2e2;
  padding: 12px;
  border-radius: 36px;
}

.card-header.savings p {
  font-size: 12px;
}

p.card-text {
  font-weight: bold;
}

.download-btn1 {
  padding-top: 30px;
}

section.aim {
  padding-top: 80px;
}

.form-parent h1 span {
  color: #066d72;
  font-size: 20px;
}

.form-parent h1 {
  font-size: 70px;
  font-weight: bold;
}

h1.experience {
  display: flex;
  align-items: center;
}

.bottom-left {
  position: absolute;
  right: 50%;
  bottom: -34px;
  text-align: left;
  transform: translate(-50%, -50%);
}

.card.savings1 {
  background-color: #066d72;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: white;
}

.card-header.savings {
  background: none;
}

.client-ctn {
  background-color: #0c0c0c;
  height: 100%;
  color: white;
  padding: 75px;
  margin: 0;
  display: grid;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.img-section img {
  width: 100%;
}

.client-ctn p {
  padding-top: 15px;
}

.client-ctn h1 {
  line-height: 53px;
}

section.client {
  padding-top: 80px;
}

p.trusted-ctn {
  padding: 18px 5px 1px 0px;
}

.load li {
  line-height: 35px;
}

.load li {
  list-style: none;
}

.load span {
  margin-left: 15px;
}

.load ul {
  padding: 0;
}

.earning-heading {
  text-align: center;
  margin-bottom: 30px;
}

p.card-text1 {
  font-weight: bold;
  color: #066d72;
  font-size: 24px;
}

p.card-text11 {
  font-weight: bold;
  color: white;
  font-size: 24px;
}

h6.card-subtitle.text-body-secondary {
  margin: 30px 0;
  font-size: 20px;
}

a.card-link {
  color: #066d72;
  text-decoration: none;
  font-size: 32px;
  font-weight: 600;
}

.btn-p {
  padding-top: 16px;
}

.card.black {
  background-color: black;
  border-radius: 20px;
  color: white;
}

a.card-link1 {
  color: white;
  text-decoration: none;
  font-size: 32px;
  font-weight: 600;
}

.card.border {
  border-radius: 20px;
}

.card.border:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: box-shadow 0.5s ease;
}

.card.black:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: box-shadow 0.5s ease;
}

.earning-heading h1 {
  font-size: 48px;
}

section.earning {
  padding-top: 80px;
}

.banner-ctn1 h1 {
  font-size: 75px;
  font-weight: bold;
}

.download-btn11 {
  padding-top: 30px;
  gap: 20px;
}

button.video-btn {
  background-color: white;
}

button.video-btn a span {
  color: black;
  font-weight: bold;
}

button.video-btn img {
  vertical-align: sub;
}

@media (max-width: 320px) {
  .card.border {
    margin-top: 30px;
  }
  .card.black {
    margin-top: 30px;
  }
  .download-btn {
    display: flex;
    justify-content: center;
  }
  .client-ctn {
    border-radius: inherit;
    padding: 35px;
  }
  .col-md-12.col-lg-4.col-sm-12.column {
    padding-top: 30px;
  }
  .fakeimg.child {
    padding: 23px;
  }
  .dowload-parent {
    text-align: center;
  }
  section.aim {
    padding: 50px 0;
  }
  section.case {
    padding: 50px 0;
  }
  button.getstart.footer-btn {
    width: 100%;
  }
  .banner-ctn1 h1 {
    font-size: 51px;
    font-weight: bold;
    /* max-width: 620px; */
  }
  .card.banner-card {
    display: none;
  }
  .load span {
    margin-left: 12px;
    font-size: 15px;
  }
  .centeredtxt {
    font-size: 25px;
  }
  nav.social {
    display: none;
  }
  .col-md-12.col-lg-4.col-sm-12.save {
    padding-top: 35px;
  }
  section.earning {
    padding-top: 0;
  }
  .centeredtxt {
    font-size: 30px !important;
  }
  .col-md-6.col-lg-6.col-sm-12.blog11 {
    padding-top: 150px;
  }
  button.get-btn1 {
    margin-top: 30px;
  }
  .fakeimg1 {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .card.border {
    margin-top: 30px;
  }
  .card.black {
    margin-top: 30px;
  }
  .download-btn {
    display: flex;
    justify-content: center;
  }
  .client-ctn {
    border-radius: inherit;
    padding: 35px;
  }
  .col-md-12.col-lg-4.col-sm-12.column {
    padding-top: 30px;
  }
  .dowload-parent {
    text-align: center;
  }
  section.aim {
    padding: 50px 0;
  }
  section.case {
    padding: 50px 0;
  }
  button.getstart.footer-btn {
    width: 100%;
  }
  .banner-ctn1 h1 {
    font-size: 51px;
    font-weight: bold;
    /* max-width: 620px; */
  }
  .card.banner-card {
    display: none;
  }
  .load span {
    margin-left: 12px;
    font-size: 15px;
  }
  .centeredtxt {
    font-size: 30px !important;
  }
  nav.social {
    display: none;
  }
  .col-md-12.col-lg-4.col-sm-12.save {
    padding-top: 35px;
  }
  section.earning {
    padding-top: 0;
  }
  .col-md-6.col-lg-6.col-sm-12.blog11 {
    padding-top: 150px;
  }
  button.get-btn1 {
    margin-top: 30px;
  }
  .fakeimg1 {
    text-align: center;
  }
}
@property --num {
  syntax: "<integer>";
  inherits: false;
  initial-value: 1;
}
h1.experience::before {
  counter-reset: my-counter var(--num);
  content: counter(my-counter);
  animation: count 5s ease-in-out 1 alternate forwards;
}

@keyframes count {
  /*  to { --num: 5; } */
  from {
    --num: 1;
  }
  to {
    --num: 5;
  }
}
.w3-animate-right {
  position: relative;
  animation: animateright 2s;
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
.overlay {
  position: absolute;
}

.w3-animate-left {
  position: relative;
  animation: animateleft 2s;
}

button.carousel-control-next {
  background-color: transparent;
  padding-left: 10%;
}

button.carousel-control-prev {
  background-color: transparent;
  padding-right: 10%;
}

h1.fontsize {
  font-size: 45px;
  font-weight: 700;
}

button.getstart {
  width: 20%;
  font-weight: 600;
}

h2.testmonialtext {
  padding: 0;
  font-size: 1.4rem;
  font-weight: 100;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 34px;
  padding: 0px 5% 0px 5%;
}

p.testmonialname {
  display: flex;
  justify-content: center;
  font-size: 25px;
  padding: 3%;
  text-decoration: underline;
  text-decoration: underline white 3px;
  text-underline-offset: 8px;
  font-weight: 600;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  color: red;
  font-size: 20px;
}

p.abouttext {
  padding: 15px 0px 15px 0px;
}

h1.headeingtext {
  font-size: 45px;
}

/* about */
.about-banner {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px 0px 30px 0px;
  color: white;
}

.centeredtxt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  font-weight: 600;
}

.get-parent.about {
  margin-top: 80px;
}

.get-parent.about {
  margin-top: 80px;
}

.abouttext {
  font-size: 17px;
  line-height: 31px;
  text-align: start;
}

.about-perent {
  display: flex;
  align-items: center;
  gap: 23px;
  padding: 16px 40px;
}

.about-ctn {
  color: black;
}

.about-case {
  padding-top: 50px;
}

.about-ctn h5 {
  font-size: 25px;
  font-weight: 600;
}

.about-ctn p {
  margin-top: 20px;
}

h1.client {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* @property --num {
    syntax: '<integer>';
    inherits: false;
    initial-value: 1;
}

h1.client::before {
    counter-reset: my-counter var(--num);
    content: counter(my-counter);
    // animation:  count 1s ease-in-out infinite alternate forwards;
    animation: count 10s ease-in-out 1 alternate forwards
}
@keyframes count {


    from {
        --num: 1;
    }

    to {
        --num: 5;
    }
} */
.about-textsaving {
  font-size: 48px;
  font-weight: 700;
}

.abt-txt h5 {
  font-size: 48px;
  font-weight: 700;
}

.abt-txt {
  text-align: center;
}

.team-saving-parent {
  text-align: center;
}

.about-team h5 {
  font-size: 25px;
  font-weight: 600;
  padding-top: 23PX;
}

.about-team P {
  text-align: center;
}

.featuretxt h5 {
  font-size: 60px;
  font-weight: 700;
}

.row.special {
  display: flex;
  align-items: center;
}

.progress-bar {
  background-color: #066d72;
}

.features-parent.happy {
  margin-top: 80px;
}

.features-parent.about {
  margin-top: 80px;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 2.25rem;
}

.get-parent.feature {
  margin-top: 80px;
}

.progressbartxt h6 {
  font-size: 18px;
  font-weight: 700;
}

.rounded {
  border-radius: 1.25rem !important;
}

.card.border.our:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: box-shadow 0.5s ease;
  border: 2px solid #066d72 !important;
}

div#accordionFlushExample {
  border: 1px solid rgba(86, 80, 80, 0.3607843137);
  padding: 4%;
  border-radius: 10px;
}

button.accordion-button.collapsed:hover {
  background-color: white;
}

button.accordion-button.collapsed:focus {
  background-color: white;
}

.banner-ctn {
  padding-top: 31px;
}

button.accordion-button.feature.collapsed {
  font-weight: 700;
}

/* .img-fluid {
    max-width: 100%;
    height: auto;
    height: 100%;
} */
.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  font-weight: bold;
}

.accordion-body.feature {
  font-weight: 400;
  color: #939393;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #000000;
  color: #066D72;
  border: none;
  outline: none;
}

.accordion-button:active {
  z-index: 3;
  border-color: #000000;
  color: #066D72;
  border: none;
  outline: none;
}

section.inerpricing {
  padding-top: 80px;
}

section.speciality {
  padding-top: 80px;
}

section.iner-pricing {
  background-color: black;
  margin-top: 60px;
  padding: 100px;
}

h1.headeingtext.price {
  color: white;
}

.saving-parent1.price {
  color: white;
  padding-top: 25px;
}

h1.headeingtext.price.faq {
  color: black;
}

section.iner-faq {
  padding-top: 80px;
}

button.plan-btn {
  width: 100%;
  font-weight: bold;
}

.planbtn-p {
  padding-top: 30px;
}

.project {
  padding-top: 37px;
}

button.plan-btn1 {
  width: 100%;
  background-color: white;
  color: black;
  font-weight: bold;
}

h6.card-subtitle.text-body-secondary.plan {
  font-size: 30px;
  font-weight: 700;
}

.project p span {
  margin-left: 13px;
}

h1.plan-num {
  font-weight: bold;
  font-size: 30px;
}

.card.black.plan {
  background-color: black;
  border-radius: 20px;
  color: white;
  margin-top: 80px;
}

.card.border.plan {
  background-color: #066d72;
  color: white;
}

button.plan-btn {
  width: 100%;
  font-weight: bold;
  background-color: white;
  color: black;
}

.bottom-left {
  position: absolute;
  right: 29%;
  bottom: -205px;
  text-align: left;
  width: 310px;
  transform: translate(-50%, -50%);
}

.parent-banner span {
  font-size: 14px;
  font-weight: 600;
}

.text-banner span {
  font-size: 11px;
}

.parent-banner1 span {
  font-size: 16px;
  font-weight: bold;
}

.text-banner1 span {
  font-size: 16px;
  font-weight: 600;
  color: #5E4EA1;
}

.card.banner-card {
  border-radius: 19px;
  width: 289px;
}

.card.banner-card {
  border-radius: 19px;
  width: 261px;
}

.fakeimg1 img {
  width: 83%;
}

.features-parent {
  margin-top: 150px;
}

.progress-bar.banner {
  background-color: #5E4EA1;
}

.progress-bar.banner1 {
  background-color: #F9BA33;
}

.progress-bar.banner2 {
  background-color: #3BBB6E;
}

.text-banner12 span {
  font-size: 16px;
  font-weight: 600;
  color: #F9BA33;
}

.text-banner13 span {
  font-size: 16px;
  font-weight: 600;
  color: #3BBB6E;
}

/* contant us */
h6.card-subtitle.contantus {
  /* margin: 13px; */
  margin-top: 23px;
  font-weight: bold;
}

button.formcontact {
  color: black;
  background-color: white;
}

.map-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.features-world {
  padding: 113px 0;
}

section.Address {
  padding-top: 100px;
}

section.features {
  background-color: black;
  color: white;
  margin-top: 100px;
}

p.add-ctn {
  margin-top: 18px;
}

section.world {
  padding-top: 80px;
}

.saving-parent11.contact {
  color: black;
}

p.card-text.contact {
  color: black;
}

.row.justify-content-between.price-card.aos-init {
  padding-top: 30px;
}

.social-links li {
  margin-right: 10px;
  list-style: none;
}

.social-links ul {
  display: flex;
  padding: 0;
  margin-top: 15px;
}

.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 5%;
}

.textmove {
  font-size: 22px;
}

.blog-image img {
  border-radius: 25px;
}

.blog-image {
  text-align: center;
  position: relative;
}

.blog-image::after {
  position: absolute;
  content: "";
}

.bottom-blog {
  position: absolute;
  /* text-align: center; */
  width: 90%;
  bottom: -48px;
  transform: translate(6%, 17%);
  /* border-radius: 20px; */
}

.card.blog {
  border-radius: 20px;
}

.card-body.blog {
  text-align: left;
  padding: 30px;
}

.card-body.blog h5 {
  font-weight: 600;
}

.card-body.blog p {
  padding-top: 8px;
  line-height: 29px;
  color: grey;
  margin: 0;
}

.blog-btn {
  padding-top: 20px;
  font-weight: 600;
}

.blog-btn a {
  text-decoration: none;
  color: #066d72;
}

a.blog-button:hover {
  color: #066d72;
}

.card-body.blog h4 {
  font-weight: 700;
}

.row.blogg {
  padding-top: 150px;
}

marquee {
  background-color: black;
  color: white;
  padding: 6px;
  font-size: 16px;
}

marquee span {
  margin-left: 250px;
}

.card.border.aa {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: box-shadow 0.5s ease;
}

.pptext {
  color: #066d72;
}

.privacy-ctn p {
  margin: 20px 0;
}

.headeingtextpp {
  font-size: 42px;
  font-weight: 700;
  margin: 40px 0;
  color: #000000;
}

ul.ppul li {
  padding: 10px 0;
}

button.getstart.footer-btn {
  background-color: white;
  color: black;
}

button.getstart.footer-btn.hover a:hover {
  color: black;
}

button.getstart.footer-btn a:hover {
  color: black;
}

.row.blogg.last {
  padding-bottom: 80px;
}

nav a {
  margin-right: 10px;
}

.privacy-ctn li {
  margin-top: 20px;
}

h1.headeingtextppblog1 {
  font-size: 31px;
  font-weight: 600;
}

h1.headeingtextppblog {
  font-size: 31px;
  font-weight: 600;
  padding-top: 40px;
}

.social {
  position: fixed;
  top: 30%;
}

.social ul {
  list-style-type: none;
  padding: 0;
  transform: translatex(-270px);
}

.social ul li {
  display: block;
  margin: 9px 17px;
  background-color: rgb(255, 255, 255);
  width: 300px;
  text-align: right;
  padding: 14px;
  border-radius: 0 30px 30px 0;
  transition: all 1s;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.social ul li:hover {
  transform: translatex(110px);
}

.social ul li.twitter:hover {
  background-color: #3b5999;
}

.social ul li.facebook:hover {
  background-color: #e4405f;
}

.social ul li.google-plus:hover {
  background-color: #25d366;
}

.social ul li a {
  color: white;
  text-decoration: none;
}

.social ul li i {
  text-align: center;
  margin-left: 14px;
  color: black;
  padding: 10px;
  border-radius: 50%;
  font-size: 20px;
  transform: rotate(0deg);
}

.social ul li:hover i {
  transform: rotate(360deg);
  transition: all 1s;
}

a.ic {
  margin: 0;
}

section.trusted {
  padding-top: 30px;
}

div#accordionFlushExample1 {
  border: 1px solid rgba(86, 80, 80, 0.3607843137);
  padding: 4%;
  border-radius: 10px;
}

div#accordionFlushExample1 {
  margin-top: 40px;
}

select#cars {
  width: 100%;
  padding: 10px 15px;
}

input#exampleInputEmail11 {
  background-color: white;
}

select#cars {
  background-color: white;
  color: #6c757d;
}

.row.aboutnew {
  display: flex;
  align-items: center;
}

.card.black.plan p {
  font-size: 14px;
  line-height: 32px;
}

.card.border.plan p {
  font-size: 14px;
  line-height: 32px;
}

button.form1 {
  background-color: #066d72;
  color: white;
  font-weight: 700;
  border-radius: 3px;
}

.planbtn-p a {
  color: black;
}

button.getstart.footer-btn a {
  color: black;
}

button.getstart.footer-btn a:hover {
  color: white;
}

.form-parent32 {
  display: flex;
  align-items: center;
  gap: 21px;
}

.form-parent32 span {
  color: #0e6e72;
  font-size: 20px;
  font-weight: 700;
}

strong {
  font-size: 70px;
}

.form-parent32 h1 {
  font-size: 70px;
  font-weight: bold;
}

h4.details-blog {
  padding-top: 23px;
}

.col-sm-12.col-md-6.col-lg-6.blog1.ng-star-inserted {
  padding-top: 130px;
}